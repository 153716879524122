import React from 'react';
import './Tracking.css';
import LogoTracking from '../ressources/logo-tracking.png';

function Tracking() {
  const idTracking = window.location.pathname.split('/')[1];
  console.log('idTracking: ', idTracking);

  const [trackingData, setTrackingData] = React.useState(null);

  React.useEffect(() => {
    if (!idTracking) {
      return;
    }
    fetch(`https://email-tracking-server-production.up.railway.app/deliverySteps?trackingNumber=${idTracking}`)
      .then(response => response.json())
      .catch(error => console.error('Error:', error))
      .then(data => {
        console.log('data: ', data);
        setTrackingData(data);
      });
  }, []);



  return (
    <div>
       <div style={
        {
          display: 'flex',
          justifyContent: 'center',
          padding: '0px 0px 0px 0px',
          backgroundColor: '#f8f8f8',
        }
      }
      onClick={() => {
        console.log('redirecting to /');
        window.location = '/';
      }}
      >
          <img src={LogoTracking} alt="Logo Tracking" style={{
            height: 'auto',
          }}/>
      </div>
      {/* make a tracking carrier page (like DHL, UPS, etc.) with the tracking number and information day per day */}
      <div className="tracking-container">
        { trackingData && trackingData.deliveryStep.length === 0 && <p>Votre colis n'a pas encore été expédié...</p>

        }
        { !trackingData && <p><br/><br/>Votre numéro de colis n'existe pas...</p>}
        { trackingData && trackingData.deliveryStep.length > 0 &&
        <div>
              <h1><br/>Suivi de votre commande</h1>
              <p>Numéro de commande: {idTracking}</p>
              <p>Livreur: DHL</p>
              {trackingData && trackingData.deliveryStep.map((step, index) => (
                <div key={index} className="tracking-step">
                  <p>{step.date}</p>
                  <p>{step.step}</p>
                  <p>{step.location}</p>
                </div>
            ))}
          </div>
        }
      </div>
      <footer>
        <p>© 2024 Suivis Livraison SAS. Tous droits réservés.</p>
      </footer>
    </div>
  );
}

export default Tracking;
