
import React, { useState } from 'react';
import './Home.css';
import LogoTracking from '../ressources/logo-tracking.png';


import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(faSearch);




function Home() {

  const [trackingNumber, setTrackingNumber] = useState('');

  const onSubmit = (e) => {
    if (trackingNumber === '') {
      console.log('trackingNumber is empty');
    } else {
      // redirect to /:trackingNumber
      console.log('redirecting to /:trackingNumber');
      window.location = `/${trackingNumber}`;
    }
  };


  return (
    <div>
      <div style={
        {
          display: 'flex',
          justifyContent: 'center',
          padding: '0px 0px 0px 0px',
          backgroundColor: '#f8f8f8',
        }
      }
      >
          <img src={LogoTracking} alt="Logo Tracking" style={{
            height: 'auto',
          }}
          />
      </div>
        <div class="wrapper">
          <div class="container">
            <div class="search-form form" style={{display: 'flex'}}>
              <label>
                  <span class="screen-reader-text">Rechercher votre colis...</span>
                  <input type="search" class="search-field" placeholder="Rechercher votre colis..." value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} name="s" title="Rechercher votre colis..." />
              </label>
              <div className='button' style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0px 20px 0px 20px',
                  fontSize: '17px',
                }} 
                onClick={() => {
                  console.log('searching for tracking number: ', trackingNumber);
                  onSubmit();
                }}
                >
                <FontAwesomeIcon icon="search" />
                <span className="search-icon" />
              </div>
          </div>
          </div>
        </div>
        <footer>
          <p>© 2024 Suivis Livraison SAS. Tous droits réservés.</p>
        </footer>
    </div>
  );
}

export default Home;
